<template>
  <div class="container">
    <div v-if="isShow">
      <div class="imgbg">
        <img v-if="info.h5_img" :src="info.h5_img" alt="" />
        <img v-else-if="info.try_h5_img" :src="info.try_h5_img" alt="" />
        <img v-else src="../assets/istock.jpg" alt="" />
      </div>

      <div class="btnbox" v-if="obj.isShare == 0">
        <div class="btn1" @click="pay" v-if="payStatus == 0">
          立即支付 {{ info.price }}元
          <span class="market_price" v-if="showmark(info.market_price)"
            >{{ info.market_price }}元</span
          >
        </div>
        <div class="btn1" v-if="payStatus == 1">已支付</div>
        <div class="btn1" v-if="payStatus == 2">已取消</div>
        <div class="btn1" v-if="payStatus == 3">已退款</div>
      </div>
      <div v-else class="btnbox">
        <div class="btn1" @click="pay1">立即领取</div>
      </div>

      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '70%', borderRadius: '32px 32px 0px 0px' }"
      >
        <div class="popup">
          <div class="title">
            听股票支付协议
            <img src="../assets/close.png" alt="" @click="close" />
          </div>
          <div class="content">
            <div class="contract">
              <h4>应用名称：</h4>
              <p>听股票</p>

              <h4>开发者名称：</h4>
              北京石成鑫教育咨询有限公司

              <h4>常用办公地址：</h4>
              <p>北京市朝阳区化工路59号院1号楼1至14层01内A座三层305室</p>
              <h4>信息保护负责人联系电话：</h4>
              <p>010-87398712</p>
              <h4>特别提示：</h4>
              <p>
                您在同意协议前应完整、仔细地阅读本协议，您同意并继续支付将被视为完全理解并接受以下全部协议条款。您在听股票程序（下称“APP”）上同意本支付协议后，即成为本支付协议之授权人，该授权即刻发生效力。您如果不同意以下协议条款，请勿进行后续操作。
              </p>
              <p>
                一、授权人授权“听股票”通过第三方支付平台划扣结算费用。结算费用是指授权人通过
                APP 提交的订单上记载的总费用。
              </p>
              <p>
                二、在授权人成功提交订单后，听股票依照 APP
                上公布的收费规则计算结算费用。授权人应在5分钟内根据页面指示完成支付。
              </p>
              <p>
                三、如因授权人在第三方支付平台中的支付账户被锁定、盗用、被往来银行拒绝或账户无效等，以致支付账户付款失败时，听股票有权中止与授权人之间的服务协议
              </p>
              <p>
                四、授权人如有冒用、盗用他人支付账户之行为，须自行承担法律责任
              </p>
              <p>
                五、如您使用第三方支付平台完成支付，第三方会通过 SDK
                收集您的以下信息完成支付，请您查看第三方隐私政策了解您的信息收集和使用情形。可能涉及到的第三方和收集的信息如下：
              </p>

              <div class="t1">一、微信支付：</div>
              <p>
                SDK
                的来源及联系方式：深圳市腾讯计算机系统有限公司，深圳市南山区粤海街道麻岭社区科技中一路腾讯大厦35层
              </p>
              <p>收集个人信息的方式：SDK 自动收集</p>
              <p>收集的信息：无</p>
              <div class="t1">第三方隐私政策链接：</div>
              <p>
                <a
                  href=" https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl"
                >
                  https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl
                </a>
              </p>

              <div class="t1">二、支付宝支付：</div>
              <p>
                SDK
                的来源及联系方式：支付宝（中国）网络技术有限公司，中国(上海)自由贸易试验区南泉北路447号15层
              </p>
              <p>收集个人信息的方式：SDK 自动收集</p>
              <p>
                收集的信息：手机 WIFI 信息、获取正在运行的应用列表、获取手机 ID
                信息、获取 MAC 地址
              </p>
              <div class="t1">第三方隐私政策链接：</div>
              <p>
                <a href=" https://docs.open.alipay.com/54">
                  https://docs.open.alipay.com/54
                </a>
              </p>
              <p style="margin-top: 10px">
                三、如您使用 Apple iTunes 进行支付，Apple 还需收集您的 Apple ID
                完成订单支付。
              </p>
              <p>
                四、为保障支付安全、进行正常结算及提供更好的产品服务，听股票将收集、记录您充值购买虚拟币并在本平台相关功能产品/服务中使用该虚拟币所产生的数据，包括但不限于充值、购买虚拟礼物、打赏等产生的行为信息、交易记录（包括交易内容、交易状态、支付方式、金额、交易时间、交易单号）和账号余额等信息。
              </p>
              <p>
                五、在 APP
                内使用听股票的付费项目，如果授权人要求退款且听股票同意退款，退款会返回支付时所使用的第三方支付平台账户。
              </p>
              <p>
                六、授权人同意其消费产生的结算费用以听股票记录的数据为准，听股票将通过APP告知授权人。授权人对结算费用有异议，请通过听股票App联系客服/拨打客服电话进行处理。
              </p>
            </div>
          </div>
          <div class="check">
            <van-checkbox
              v-if="times == 0"
              v-model="checked"
              checked-color="#ee0a24"
              shape="square"
              >我已仔细阅读并同意签署</van-checkbox
            >
            <div
              class="btn"
              @click="agree"
              :style="{ background: checked ? '#EF0923' : '#F78491' }"
            >
              同意签署
              <span v-if="times > 0 && !checked">（{{ times }}秒）</span>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";

import {
  receiveTryProduct,
  pay,
  scrmGetOpenid,
  hasProductOrder,
  getinfo,
} from "../utils/api";
import axios from "axios";
import { Dialog } from "vant";
export default {
  name: "crmpay",
  data() {
    return {
      show: false,
      checked: false,
      times: 15,
      timeout: null,
      is_try: 1,
      obj: JSON.parse(localStorage.getItem("urlkey")) || null,
      payStatus: 0,
      paytimer: null,
      paycode: 0,
      api: "/api",
      info: {},
      isShow: false,
    };
  },
  watch: {
    show: {
      handler(n, o) {
        if (!n) {
          this.times = 15;
          clearTimeout(this.timeout);
          this.checked = false;
        }
      },
    },
    checked: {
      handler(n, o) {
        if (n) {
          clearTimeout(this.timeout);
        } else {
          // this.timerfn();
        }
      },
    },
  },
  methods: {
    showmark(val) {
      let flg = false;
      if (val) {
        flg = true;
        if (val == "0.00" || val == "0") {
          flg = false;
        }
      }
      return flg;
    },
    getinfofn() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
      });
      getinfo({ product_id: this.obj.product_id }).then((res) => {
        console.log(res, "555555");
        document.title = res.data.name;
        this.info = res.data;
        this.isShow = true;
        Toast.clear();
      });
    },
    paystatusfn() {
      // 订单状态 0待支付 1已支付 2已取消 3已退款
      axios
        .request({
          url: `${this.api}/orders/${this.obj.no}/status`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "get",
        })
        .then((res) => {
          console.log(res.data, "99999999999999");
          // this.payStatus = res.data.state;
          if (res.data.state == 1) {
            clearTimeout(this.paytimer);
            document.title = "";
            this.$router.replace({
              path: "/payend",
              query: {
                type: 1,
              },
            });
          }
        })
        .catch(function (error) {
          //console.log(error.log)
        });
    },
    agree() {
      let that = this;
      if (!this.checked) {
        Toast("请仔细阅读并签署合同");
        return;
      }
      let name = this.obj.username;
      this.$router.push({
        path: "/crmPay",
        query: {
          userPhone: this.obj.tel,
          crm_weukql: this.info.crm_weukql,
          tname: this.info.tname,
          price: this.info.price,
          pname: this.info.user_info.name,
          proName: this.info.name,
          is_use_coupon: this.info.is_use_coupon,
          market_price: this.info.market_price,
          username: name,
          isRecord: 1,
          iszk: 1,
          product_id: this.obj.product_id,
        },
      });
    },
    pay() {
      // debugger;
      this.show = true;
      this.timerfn();
    },
    close() {
      this.show = false;
      this.times = 15;

      clearTimeout(this.timeout);
    },
    pay1() {
      console.log(" this.obj.id", this.obj.id);
      receiveTryProduct({
        share_link_log_id: Number(this.obj.id),
      }).then((res) => {
        Toast(res.message);
        if (res.code == 0 || res.code == 100006) {
          document.title = "";
          this.$router.replace({
            path: "/payend",
            query: {
              type: 0,
            },
          });
        }
      });
    },
    timerfn() {
      this.timeout = setInterval(() => {
        this.times--;
        if (this.times == 0) {
          clearTimeout(this.timeout);
          this.times = 0;
        }
      }, 1000);
    },
  },
  mounted() {},
  destroyed() {
    clearTimeout(this.paytimer);
    // document.title = this.obj.product_name;
  },
  mounted() {},
  created() {
    console.log(this.$route.query, "55555555");

    // is_try    1 体验  0  分享
    // crm_weukql  课时
    // product_name  课程名称
    let url = null;
    let wurl = window.location.href;

    // if (wurl.indexOf("Vconsole") > -1) {
    //   new Vconsole();
    // }

    if (wurl.indexOf("is_try") > -1) {
      url = wurl;
    } else {
      url =
        "http://gray.lecturer.tinggupiao.com.cn/auth/login?is_try=0&tel=18816091320&id=222&isShare=0&code=825222&username=1&product_id=222";
    }
    var search = url.split("?")[1];
    var t = search.split("&");
    var key = {};
    t.map((item, index) => {
      var b = item.split("=");
      key[b[0]] = b[1];
    });
    this.obj = key;
    console.log(key, "444444");
    localStorage.setItem("urlkey", JSON.stringify(this.obj));
    document.title = "听股票";
    this.paystatusfn();
    this.getinfofn();
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-checkbox__label {
  font-size: 16px;
}
.container {
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  img {
    display: block;
    width: 100%;
    // height: 100%;
  }
  .imgbg {
    flex: 1;
  }
  .title {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    color: #333333;
    text-align: center;
    padding-top: 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 12px;
      height: 12px;
      display: block;
      position: absolute;
      right: 20px;
      top: 30px;
    }
  }
  .content {
    width: 100%;
    // height: 200px;
    // background: red;
    flex: 1;
    overflow-y: auto;
    // padding:0 15px;
    padding-bottom: 15px;
    .contract {
      margin: 0 15px;
      padding: 0 10px;
      font-size: 13px;
      // width:100%;
    }
  }
  .check {
    border-top: 1px solid #f8f8f8;
    padding-top: 14px;
    padding-left: 16px;
  }
  .btn {
    margin: 16px 16px 12px;
    height: 44px;
    background: #f78491;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    color: #ffffff;
    font-size: 16px;
  }
  .btn1 {
    margin: 16px 16px 12px;
    height: 44px;
    background: #ef0923;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    // font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn2 {
    margin: 16px 16px 12px;
    height: 44px;
    background: #ccc;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    // font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ssss {
    width: 100%;
    height: 500px;
    position: fixed;
    top: 0;
    left: 0;
    background: red;
  }
  .btnbox {
    position: fixed;
    width: 100%;
    // background: red;
    bottom: 0px;
  }
  .t1 {
    font-size: 14px;
    padding: 10px 0;
    font-weight: bold;
  }
  .market_price {
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 11px;
    margin-left: 10px;
  }
}
</style>
